<template>
    <BaseCardCollection
        v-if="cards.length"
        :cards="cards"
    />
    <p v-else>
        There are no news articles related to this page right now.
        <nuxt-link to="/news"> See all news articles </nuxt-link>
    </p>
</template>

<script setup>
import relatedNewsQuery from '~/graphql/queries/news/Related.graphql';
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();

const props = defineProps({
    tags: {
        type: Array,
        required: false,
        default: () => [],
    },
    relatedToEntryId: {
        type: [Number, String],
        required: false,
        default: null,
    },
    relatedToTagId: {
        type: [Number, String],
        required: false,
        default: null,
    },
    excludeIds: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const { variables } = useCraftVariables();
if (props.tags) {
    variables.tagIds = props.tags;
}
if (props.relatedToEntryId) {
    variables.relatedToEntries = [{ id: props.relatedToEntryId }];
}
if (props.relatedToTagId) {
    variables.relatedToTags = [{ id: props.relatedToTagId }];
}
if(props.excludeIds?.length) {
    variables.exclude = ['not', ...props.excludeIds];
}

const { data, pending } = await useLazyAsyncQuery({
    query: relatedNewsQuery,
    variables,
});

const emit = defineEmits(['empty']);
// Watch pending state, emit empty event if no news items are found.
watch(pending, (value) => {
    if (!value && !toValue(data)?.news?.length) {
        emit('empty');
    }
});

const date = useDate();
const pillsCard = usePillsCard();
const cards = computed(() => {
    const news = toValue(data)?.news;
    if (news) {
        return news.map((newsItem) => {
            let image = null;
            if (newsItem.image.length) {
                image = {
                    src: newsItem.image?.[0]?.large,
                    srcset: getSrcSet(newsItem.image?.[0], 'default'),
                };
            }
            return {
                image,
                title: newsItem.title,
                meta: date.formatSingleDate(
                    newsItem.postDate,
                    date.formats.dayMonthYear
                ),
                pills: pillsCard.getCardPills(newsItem),
                uri: newsItem.uri,
            };
        });
    }
    return [];
});
</script>
